import { Component } from "react";
import { allCourses } from "../statics/content";
import { DragMoreBtn } from "../components/form";
import ScreenHeader, { ScreenFooter } from "../components/foot&head";
import AboutContainer from "../components/about";
import EventsList from "../components/eventsList";
import cs from "../styles/academy.module.css";

export default class AcademyScreen extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: "home", //home //about //courses //trainer
    };
  }

  async componentDidMount() {
    const page = window.location.pathname.split("/")[2];
    this.setState({ page });
  }

  _setPage = (page: string) => {
    window.history.replaceState("UC", "UC", "/academy/" + page);
    this.setState({ page });
  };

  render() {
    const { page }: any = this.state;
    return (
      <div className={cs.body}>
        <div className={cs.banner}>
          <div className={cs.bannerBody}>
            <div className={cs.title}>UC ACADEMY</div>
            <div className={cs.desc}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COME AND INROLL IN DUBAI FIRST
              CERTIFIED MODEL ACADEMY . WE TEACH EVERYTHING WHAT A MODEL SHOULD
              KNOW BOTH THEORETICAL AND PRACTICAL .<br />
              From history of models and fashion to all kind of catwalks and
              posing .<br />
              Learn how to build your trademark and how to enter the modelling
              world internationally . Plus we build some of yourportfolio
              pictures and participants in fashion weeks.
            </div>
            <div
              className={cs.moreBtn}
              style={{ float: "left" }}
              onClick={() => this._setPage("courses")}
            >
              KNOW THE COURSES UC PROVIDES
            </div>

            <div
              className={cs.moreBtn}
              style={{ float: "left" }}
              onClick={() => this._setPage("trainer")}
            >
              KNOW ABOUT TRAINER
            </div>
          </div>
          <DragMoreBtn
            onClick={() => this._setPage("about")}
            title="CLICK TO VIEW MORE ABOUT UC ACADEMY"
          />
        </div>
        <div className={page === "about" ? cs.detailsPage : cs.detailsPage_}>
          <div className={cs.bgImage} />
          <div className={cs.contentArea}>
            <video className={cs.trainingVedio} autoPlay playsInline loop muted>
              <source
                src="https://public.ucfashionweek.com/uc/movingBg/trainingShow.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div className={cs.trainerDiv}>
              <div className={cs.title}>ABOUT UC ACADEMY</div>
              <div className={cs.desc}>
                modeling academies offer a range of classes and workshops to
                teach aspiring models various aspects of the industry, including
                runway walking, posing, photography, makeup application,
                skincare, and fashion styling. these classes help models refine
                their skills and gain confidence.
                <br />
                <br />
                All our models will be having the oppertunity to join in
                diffrent catwalks and competitions !!
                <br />
                <br />
                COME AND INROLL in the most academic international model academy
                in Dubai. We give different courses and packages .
              </div>
              <div
                className={cs.moreBtn}
                onClick={() => this._setPage("courses")}
              >
                KNOW THE COURSES UC PROVIDES
              </div>
            </div>
          </div>
          {/* <DragMoreBtn
            onClick={() => this._setPage("courses")}
            title="CLICK TO VIEW KNOW THE COURSES UC PROVIDES"
          /> */}
        </div>
        <div className={page === "courses" ? cs.detailsPage : cs.detailsPage_}>
          <div className={cs.bgImage1} />
          <div className={cs.contentArea}>
            <div className={cs.title}>MODDELLING COURSE</div>
            <div className={cs.desc}>
              All Encompassing Indrodection Course to the Modelling industry
            </div>
            <div className={cs.itemsRow}>
              {allCourses.map((it, k) => (
                <div className={cs.eachItem} key={k}>
                  {/* <div className={cs.itemNumber}>{k + 1}</div> */}
                  {/* <div className={cs.eachItem_}> */}
                  <div className={cs.title3}>{it.title}</div>
                  {/* <div className={cs.desc}>{it.desc}</div> */}
                  {/* </div> */}
                </div>
              ))}
            </div>
          </div>
          <DragMoreBtn
            onClick={() => this._setPage("trainer")}
            title="CLICK TO VIEW KNOW ABOUT TRAINER"
          />
        </div>
        <div className={page === "trainer" ? cs.detailsPage : cs.detailsPage_}>
          <div className={cs.bgImage2} />
          <div className={cs.contentArea}>
            <img
              className={cs.trainingVedio}
              src={require("../assets/bg/bibo.jpg")}
              alt="MediaPic"
            />
            <div className={cs.trainerDiv}>
              <div className={cs.title1}>BIBO</div>
              <div className={cs.whiteLine} />
              <div className={cs.desc}>The Director & Manager</div>
              <br />
              <div className={cs.desc}>
                Ehab Al-Ali (Bibo) is a synonymous with luxury and modernism. A
                proud, serious artist with a powerful and unique vision. BIBO
                takes luxury to its zenith, design and create for his clients
                that is more dreamlike in its professionality! Born in Dubai and
                Raised in Sweden.Through the years Bibo has organized more than
                collections and directing for other shows ! Presented Sweden in
                Milano fashion week , Paris fashion week and Africa fashion week
                ! And was the head stylist for Arab Film festival (Sweden ), and
                in Cannes film festival (France ). Organized peagents as Miss
                Teen Scandinavia , and Miss Earth Sweden. Worked with Miss
                Univers (the original peagent ) , Miss Universe Sweden, Miss
                world (the original ) and mIss Teen Asia. Memeber for many
                Organizations that protect human Rights and Woman Rights , and
                organized charity fashion shows that is related to Palestine ,
                Afghanistan , and the Gambia . Bibo has worked with the Royal
                family of Bahrain as the personal stylist of European and arab
                celbrities , such as , Asala , Ahlam , Dima Bayah , Sherine ,
                Nisriene tafesh , Carola (Swedish) , Andres esteche (latin
                Swedish ) , linda Bengtzing (Swedish) , cicilia Norby (Danish )
                , Alina danylenko (Ukraine) … and many more.
              </div>
            </div>
          </div>
          <DragMoreBtn
            onClick={() => this._setPage("home")}
            title="BACK TO ACADAMY HOME"
          />
        </div>
        <AboutContainer />
        <EventsList events={this.props.events} />
        <ScreenFooter setPage={this.props.setPage} />
        <ScreenHeader setPage={this.props.setPage} />
      </div>
    );
  }
}
