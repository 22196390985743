import { StrictMode, useState } from "react";
import cs from "./index.module.css";

export default function NewsList({ events }: any) {
  // const [selected, setselected]: any = useState(null);
  return (
    <StrictMode>
      <div className={cs.section5}>
        <div className={cs.title}>UC News</div>
        {/* <div className={cs.desc}>
        An Extravaganza of Style! 🌟 Join us for a series of exclusive fashion
        events that promise to redefine the way you experience glamour and
        trends.
      </div> */}
        <div className={cs.eventsArea}>
          {events.loading && "Loading..."}
          {events.map((it: any, k: any) => (
            <SingleNews it={it} key={k} onClick={() => window.location.href = "news?id=" + it?.id} />
          ))}
        </div>
      </div>
      {/* {selected && (
        <div className={cs.popup1} onClick={() => setselected(null)}>
          <div className={cs.popupBody}>
            <SingleNews it={selected} onClick={() => {}} lg />
          </div>
        </div>
      )} */}
    </StrictMode>
  );
}

export function SingleNews({ it, onClick, lg }: any) {
  return (
    <div className={cs.eachNews} onClick={onClick}>
      <img className={cs.newsImg} src={it?.image} alt="MediaPic" />
      <div className={cs.newsTitle}>{it?.title}</div>
      <div className={cs.newsDesc}>{it?.description}</div>
      <div className={lg ? cs.news : cs.news + " " + cs.news_sm}>
        {it?.news}
      </div>
      <br />
    </div>
  );
}
