import { useState } from "react";
import cs from "./index.module.css";

function NavBtn({ title, onClick, list }: any) {
  // const paths = window.location.pathname.split("/");
  return (
    <div className={cs.eachNavBtn}>
      <div
        onClick={onClick}
        style={{ padding: "15px 20px", boxSizing: "border-box" }}
      >
        {title}
      </div>
      {list?.length > 0 && (
        <div className={cs.eachNavBtnList}>
          {list.map((it: any, k: any) => (
            <div
              className={cs.eachNavBtnListEach}
              key={k}
              onClick={it?.onClick}
            >
              {it?.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function ScreenHeader({ setPage }: any) {
  const [clicked, setclicked] = useState(false);
  return (
    <div className={cs.header}>
      <div
        className={cs.headLogo}
        onClick={() => (window.location.href = "/")}
      />
      <div className={cs.navBar}>
        <NavBtn
          list={[{ title: "Home", onClick: () => setPage("") }]}
          onClick={() => setPage("")}
          title="Home"
        />
        <NavBtn
          list={[{ title: "All Events", onClick: () => setPage("events") }]}
          onClick={() => setPage("events")}
          title="Events"
        />
        <NavBtn
          list={[
            { title: "Agency", onClick: () => setPage("agency") },
            { title: "Register", onClick: () => setPage("agency/register") },
          ]}
          onClick={() => setPage("agency")}
          title="Agency"
        />
        <NavBtn
          list={[
            { title: "Academy", onClick: () => setPage("academy") },
            {
              title: "UC Modeling Courses",
              onClick: () => setPage("academy/courses"),
            },
            {
              title: "About Trainer",
              onClick: () => setPage("academy/trainer"),
            },
            { title: "About Academy", onClick: () => setPage("academy/about") },
          ]}
          onClick={() => setPage("academy")}
          title="Academy"
        />
        <NavBtn
          onClick={function scrolldiv() {
            var elem: any = document.getElementById("footerDiv");
            elem.scrollIntoView();
          }}
          title="Contact Us"
        />
        {/* <NavBtn
          onClick={() => setPage("careers")}
          title="Careers"
          list={[
            { title: "Careers", onClick: () => setPage("careers") },
            {
              title: "Jobs available",
              onClick: () => setPage("careers/jobs"),
            },
            {
              title: "Drop your CV",
              onClick: () => setPage("careers/drop-cv"),
            },
          ]}
        /> */}
        <div className={cs.moreBtn} onClick={() => setclicked(!clicked)} />
        {clicked && (
          <div className={cs.drower} onClick={() => setclicked(!clicked)}>
            <div className={cs.drowerBody} onClick={() => setclicked(!clicked)}>
              <div className={cs.eachNavBtnSl} onClick={() => setPage("")}>
                <b>Home</b>
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("events")}
              >
                <b>Events</b>
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("agency")}
              >
                <b>Agency</b>
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("agency/register")}
              >
                &nbsp;&nbsp;Register as a model
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("academy")}
              >
                <b>Academy</b>
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("academy/about")}
              >
                &nbsp;&nbsp;About Academy
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("academy/trainer")}
              >
                &nbsp;&nbsp;About Trainer
              </div>
              <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("academy/courses")}
              >
                &nbsp;&nbsp;UC academy courses
              </div>
              {/* <div
                className={cs.eachNavBtnSl}
                onClick={() => setPage("careers")}
              >
                <b>Careers</b>
              </div> */}
              <div
                className={cs.eachNavBtnSl}
                onClick={function scrolldiv() {
                  var elem: any = document.getElementById("footerDiv");
                  elem.scrollIntoView();
                }}
              >
                <b>Contact Us</b>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function ScreenFooter({ setPage }: any) {
  return (
    <div className={cs.footer} id="footerDiv">
      <div className={cs.contactArea}>
        {/* <div className={cs.contactUsArea}> */}
        <div
          className={cs.headLogo}
          onClick={() => (window.location.pathname = "/")}
        />
        {/* <div className={cs.text1}>Want to know more enter your mail</div>
          <form className={cs.emailInputArea}>
            <input placeholder="Enter your email" />
            <button>Enter</button>
          </form> */}
        {/* </div> */}
        <div className={cs.footContainer}>
          <div className={cs.footContainerEach}>
            <div onClick={() => setPage("")}>Home</div>
            <div onClick={() => setPage("agency")}>UC Agency</div>
            <div onClick={() => setPage("academy")}>UC Academy</div>
            <div onClick={() => setPage("events")}>All Events</div>
            <div onClick={() => setPage("careers")}>Careers</div>
          </div>
          <div className={cs.footContainerEach}>
            <div onClick={() => setPage("agency")}>Agency</div>
            <div onClick={() => setPage("agency/register")}>
              Register as a model
            </div>
            <div onClick={() => setPage("agency/register")}>
              Join on UC Agency
            </div>
          </div>
          <div className={cs.footContainerEach}>
            <div onClick={() => setPage("academy")}>Academy</div>
            <div onClick={() => setPage("academy/courses")}>
              UC Modeling Courses
            </div>
            <div onClick={() => setPage("academy/trainer")}>
              About UC Trainer
            </div>
            <div onClick={() => setPage("academy/about")}>About UC Academy</div>
          </div>
          <div className={cs.footContainerAdderss}>
            Address,
            <br />
            <br />
            The prism tower, business bay, Dubai, UAE
            <br />
            +971 456 31119
            <br />
            info@ucfashionweek.com
            <div className={cs.socialMedia}>
              <div
                onClick={() =>
                  window.open("https://www.facebook.com/universalcouturedxb")
                }
                className={cs.fb}
              />
              <div
                onClick={() =>
                  window.open("https://www.instagram.com/universalcouture_fw/")
                }
                className={cs.insta}
              />
              <div
                onClick={() =>
                  window.open("https://www.youtube.com/@universalcouturedxb")
                }
                className={cs.yt}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cs.copyRight}>
        Copyright © Avid Hilda 2023 | All rights reserved | Designed & Developed
        by avidhilda.com
      </div>
    </div>
  );
}
