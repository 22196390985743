import axios from "axios";
import { uploadToFirebase } from "../../module/initFirebase";
import { JobUser, User, UserPayload } from "../../module/structures";
import { countryList, countrycodes } from "../../statics/country";
import cs from "./index.module.css";
import { useRef, useState } from "react";

export default function RegisterForm({ on, close, onSuccesPop }: any) {
  const [userForm, setUserForm]: any = useState(new User());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const _setValue = (e: any, prop: any) => {
    userForm[prop] = e.target.value;
    setUserForm({ ...userForm });
  };

  const _setFile = (e: any, prop: any) => {
    userForm[prop] = e.target.files[0];
    setUserForm({ ...userForm });
    // uploadToFirebase(e.target.files[0]).then((url: any) => console.log(url));
  };

  const refImg: any = useRef();
  const refImg1: any = useRef();
  const refImg2: any = useRef();
  const refImg3: any = useRef();

  const _onSubmitUserForm = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const { firstName, lastName, email, password, c_password }: any = userForm;
    const { country, mobile, gender, dob, height, waist, hips }: any = userForm;
    const { chest, image_portrait, image_portrait_side }: any = userForm;
    const { cuntryCode, image_fullbody, image_fullbody_side }: any = userForm;

    var _error = "";
    if (firstName === "") _error = "Enter first name";
    if (lastName === "") _error = "Enter last name";
    if (email === "") _error = "Enter email";
    if (email.split(".").length < 2) _error = "Not a valid email";
    if (email.split("@").length < 2) _error = "Not a valid email";
    if (password === "") _error = "Enter password";
    if (c_password === "") _error = "Enter confirm password";
    if (password !== c_password) _error = "Password is diffrent";
    if (country === "") _error = "Select country";
    if (cuntryCode === "") _error = "Select country code";
    if (mobile === "") _error = "Enter mobile";
    if (gender === "") _error = "Select gender";
    if (dob === "") _error = "Enter date of birth";
    if (height === "") _error = "Enter height";
    if (waist === "") _error = "Enter waist";
    if (hips === "") _error = "Enter hips";
    if (gender !== "Male") if (chest === "") _error = "Enter chest";
    if (image_portrait === "") _error = "Upload all photos";
    if (image_portrait_side === "") _error = "Upload all photos";
    if (image_fullbody === "") _error = "Upload all photos";
    if (image_fullbody_side === "") _error = "Upload all photos";

    setError(_error);
    if (_error === "") {
      const body: any = new UserPayload(userForm);
      await Promise.all([
        uploadToFirebase(image_portrait),
        uploadToFirebase(image_portrait_side),
        uploadToFirebase(image_fullbody),
        uploadToFirebase(image_fullbody_side),
      ]).then(async (res: any) => {
        body.image_portrait = res[0];
        body.image_portrait_side = res[1];
        body.image_fullbody = res[2];
        body.image_fullbody_side = res[3];
        await axios
          .post("https://api.avidhilda.com/api/register", body)
          .then(() => {
            setUserForm(new User());
            onSuccesPop();
            close();
          })
          .catch((e) => {
            var er = e.response.data.message ?? "Error on uploading your files";
            setError(er);
          });
      });
    }
    setLoading(false);
  };

  return (
    <div className={on ? cs.register : cs.register_}>
      <div className={cs.regPopup}>
        <div className={cs.regHeader}>
          Sign Up
          <div className={cs.closeBtn} onClick={close} />
        </div>
        <form className={cs.regBody} onSubmit={_onSubmitUserForm}>
          <div className={cs.regDesc}>
            To start the process of becoming a model, you are required to fill
            out an application form and provide us with some information along
            with polaroid photographs. These photos should be captured naturally
            (not edited), in the daylight, without any makeup - photos taken
            with your phone are totally fine. Please ensure that the pictures
            you provide are no more than 3 months old and clearly depict your
            current appearance, hairstyle and hair length.
          </div>
          <div className={cs.regSubTitle}>Personal Information</div>
          <div className={cs.formMainRow}>
            <div className={cs.formLeft}>
              <div className={cs.formRow}>
                <div>First Name *</div>
                <input
                  onChange={(e: any) => _setValue(e, "firstName")}
                  value={userForm.firstName}
                  placeholder="Enter first name"
                />
              </div>
              <div className={cs.formRow}>
                <div>Last Name *</div>
                <input
                  onChange={(e: any) => _setValue(e, "lastName")}
                  value={userForm.lastName}
                  placeholder="Enter last name"
                />
              </div>
              <div className={cs.formRow}>
                <div>Gender *</div>
                <select
                  onChange={(e: any) => _setValue(e, "gender")}
                  value={userForm.gender}
                >
                  <option hidden>Select gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div className={cs.formRow}>
                <div>Date of birth *</div>
                <input
                  type="date"
                  onChange={(e: any) => _setValue(e, "dob")}
                  value={userForm.dob}
                />
              </div>
              <div className={cs.formRow}>
                <div>Mobile *</div>
                <select
                  style={{ width: 70 }}
                  onChange={(e: any) => _setValue(e, "countryCode")}
                  value={userForm.countryCode}
                >
                  <option hidden>00</option>
                  {countrycodes.map((it: any, k: any) => (
                    <option key={k} value={it.value}>
                      {it.value}
                    </option>
                  ))}
                </select>
                <input
                  style={{ width: "calc(60% - 120px)" }}
                  placeholder="156xxxx89"
                  type="number"
                  onChange={(e: any) => _setValue(e, "mobile")}
                  value={userForm.mobile}
                />
              </div>
              <div className={cs.formRow}>
                <div>Email *</div>
                <input
                  placeholder="example@testmail.com"
                  type="email"
                  onChange={(e: any) => _setValue(e, "email")}
                  value={userForm.email}
                />
              </div>
              <div className={cs.formRow}>
                <div>Create password *</div>
                <input
                  placeholder="********"
                  type={userForm.viewPassword ? "" : "password"}
                  onChange={(e: any) => _setValue(e, "password")}
                  value={userForm.password}
                />
                <div
                  className={userForm.viewPassword ? cs.eyeIcon_ : cs.eyeIcon}
                  onClick={() => {
                    userForm.viewPassword = !userForm.viewPassword;
                    setUserForm({ ...userForm });
                  }}
                />
              </div>
              <div className={cs.formRow}>
                <div>Confirm password *</div>
                <input
                  placeholder="********"
                  type={userForm.viewCPassword ? "" : "password"}
                  onChange={(e: any) => _setValue(e, "c_password")}
                  value={userForm.c_password}
                />
                <div
                  className={userForm.viewCPassword ? cs.eyeIcon_ : cs.eyeIcon}
                  onClick={() => {
                    userForm.viewCPassword = !userForm.viewCPassword;
                    setUserForm({ ...userForm });
                  }}
                />
              </div>
            </div>
            {/* <div className={cs.formRight}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={profilePicRef}
                  />
                  <div
                    className={cs.profilePic}
                    onClick={() => profilePicRef?.current?.click()}
                  />
                  Add your profile picture
                </div> */}
          </div>
          <div className={cs.regSubTitle}>Model Information</div>
          <div className={cs.formMainRow}>
            <div className={cs.formLeft}>
              <div className={cs.formRow}>
                <div>Height (cm)</div>
                <input
                  placeholder="0.00"
                  type="number"
                  onChange={(e: any) => _setValue(e, "height")}
                  value={userForm.height}
                />
              </div>
              <div className={cs.formRow}>
                <div>Waist (cm)</div>
                <input
                  placeholder="0.00"
                  type="number"
                  onChange={(e: any) => _setValue(e, "waist")}
                  value={userForm.waist}
                />
              </div>
              <div className={cs.formRow}>
                <div>Hips (cm)</div>
                <input
                  placeholder="0.00"
                  type="number"
                  onChange={(e: any) => _setValue(e, "hips")}
                  value={userForm.hips}
                />
              </div>
              {userForm.gender !== "Male" && (
                <div className={cs.formRow}>
                  <div>Chest (cm)</div>
                  <input
                    placeholder="0.00"
                    type="number"
                    onChange={(e: any) => _setValue(e, "chest")}
                    value={userForm.chest}
                  />
                </div>
              )}
              <div className={cs.formRow}>
                <div>Country Based</div>
                <select
                  value={userForm.country}
                  onChange={(e: any) => _setValue(e, "country")}
                >
                  <option hidden>Select Country</option>
                  {countryList.map((it: any, k) => (
                    <option key={k} value={it.code}>
                      {it.name} ({it.code})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={cs.photoUploadList}>
            <div
              className={cs.imgArea}
              onClick={() => refImg?.current?.click()}
            >
              <div className={cs.imgTitle}>Portrait Photo</div>
              <div
                className={userForm.gender === "Male" ? cs.imgImg_ : cs.imgImg}
              />
              <div className={cs.imgInput}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  ref={refImg}
                  onChange={(e: any) => _setFile(e, "image_portrait")}
                />
                <div className={cs.imgChooseFile}>Choose File</div>
                <div className={cs.imgChoosed}>
                  {userForm.image_portrait === ""
                    ? "No file chosen"
                    : userForm.image_portrait.name}
                </div>
              </div>
            </div>
            <div
              className={cs.imgArea}
              onClick={() => refImg1?.current?.click()}
            >
              <div className={cs.imgTitle}>Portrait Side Photo</div>
              <div
                className={
                  userForm.gender === "Male" ? cs.imgImg1_ : cs.imgImg1
                }
              />
              <div className={cs.imgInput}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  ref={refImg1}
                  onChange={(e: any) => _setFile(e, "image_portrait_side")}
                />
                <div className={cs.imgChooseFile}>Choose File</div>
                <div className={cs.imgChoosed}>
                  {userForm.image_portrait_side === ""
                    ? "No file chosen"
                    : userForm.image_portrait_side.name}
                </div>
              </div>
            </div>
            <div
              className={cs.imgArea}
              onClick={() => refImg2?.current?.click()}
            >
              <div className={cs.imgTitle}>Full-body Photo</div>
              <div
                className={
                  userForm.gender === "Male" ? cs.imgImg2_ : cs.imgImg2
                }
              />
              <div className={cs.imgInput}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  ref={refImg2}
                  onChange={(e: any) => _setFile(e, "image_fullbody")}
                />
                <div className={cs.imgChooseFile}>Choose File</div>
                <div className={cs.imgChoosed}>
                  {userForm.image_fullbody === ""
                    ? "No file chosen"
                    : userForm.image_fullbody.name}
                </div>
              </div>
            </div>
            <div
              className={cs.imgArea}
              onClick={() => refImg3?.current?.click()}
            >
              <div className={cs.imgTitle}>Full-body Side Photo</div>
              <div
                className={
                  userForm.gender === "Male" ? cs.imgImg3_ : cs.imgImg3
                }
              />
              <div className={cs.imgInput}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  ref={refImg3}
                  onChange={(e: any) => _setFile(e, "image_fullbody_side")}
                />
                <div className={cs.imgChooseFile}>Choose File</div>
                <div className={cs.imgChoosed}>
                  {userForm.image_fullbody_side === ""
                    ? "No file chosen"
                    : userForm.image_fullbody_side.name}
                </div>
              </div>
            </div>
          </div>
          <div className={cs.formFooter}>
            <div>{error}</div>
            <button
              type="reset"
              className="blackBorderBtn"
              disabled={loading}
              onClick={() => setUserForm(new User())}
            >
              Clear
            </button>
            <button
              type="submit"
              className={loading ? "blackBtn_" : "blackBtn"}
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export function JobForm({ on, close }: any) {
  const [userForm, setUserForm]: any = useState(new JobUser());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const _setValue = (e: any, prop: any) => {
    userForm[prop] = e.target.value;
    setUserForm({ ...userForm });
  };

  const _setFile = (e: any, prop: any) => {
    userForm[prop] = e.target.files[0];
    setUserForm({ ...userForm });
    // uploadToFirebase(e.target.files[0]).then((url: any) => console.log(url));
  };

  const refImg: any = useRef();

  const _onSubmitUserForm = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const { fullName, email, countryCode, mobile, message }: any = userForm;
    var _error = "";
    if (fullName === "") _error = "Enter name";
    if (email === "") _error = "Enter email";
    if (countryCode === "") _error = "Select Country Code";
    if (mobile === "") _error = "Select Country Code";

    setError(_error);

    await axios
      .post("https://root.ucfashionweek.com/mail/sendtocareer.php", {
        fullName,
        email,
        countryCode,
        mobile,
        message,
        recipient: "career@ucfashionweek.com",
      })
      .then(() => {})
      .catch(() => {});

    setLoading(false);
  };

  return (
    <div className={on ? cs.register : cs.register_}>
      <div className={cs.regPopup}>
        <div className={cs.regHeader}>
          APPLICATION FORM
          <div className={cs.closeBtn} onClick={close} />
        </div>
        <form className={cs.regBody} onSubmit={_onSubmitUserForm}>
          <div className={cs.regDesc}>Thank you for your interest in UC!</div>
          <div className={cs.regSubTitle}>Contact Information</div>
          <div className={cs.formMainRow}>
            <div className={cs.formLeft}>
              <div className={cs.formRow}>
                <div>Full Name *</div>
                <input
                  onChange={(e: any) => _setValue(e, "fullName")}
                  value={userForm.fullName}
                  placeholder="Enter full name"
                />
              </div>
              <div className={cs.formRow}>
                <div>Email Address *</div>
                <input
                  onChange={(e: any) => _setValue(e, "email")}
                  value={userForm.email}
                  placeholder="Enter email name"
                />
              </div>
              <div className={cs.formRow}>
                <div>Mobile *</div>
                <select
                  style={{ width: 70 }}
                  onChange={(e: any) => _setValue(e, "countryCode")}
                  value={userForm.countryCode}
                >
                  <option hidden>00</option>
                  {countrycodes.map((it: any, k: any) => (
                    <option key={k} value={it.value}>
                      {it.value}
                    </option>
                  ))}
                </select>
                <input
                  style={{ width: "calc(60% - 120px)" }}
                  placeholder="156xxxx89"
                  type="number"
                  onChange={(e: any) => _setValue(e, "mobile")}
                  value={userForm.mobile}
                />
              </div>
              <div className={cs.formRow}>
                <div>Message</div>
                <input
                  onChange={(e: any) => _setValue(e, "message")}
                  value={userForm.message}
                  placeholder="Type here... "
                />
              </div>
              <div className={cs.formRow}>
                <div>Upload CV (PDF format) *</div>
                <input
                  type="file"
                  accept="application/pdf,application/vnd.ms-excel"
                  onChange={(e: any) => _setFile(e, "cv")}
                  value={userForm.cv}
                  placeholder="Enter first name"
                />
              </div>
            </div>
          </div>

          <div className={cs.formFooter}>
            <div>{error}</div>
            <button
              type="reset"
              className="blackBorderBtn"
              disabled={loading}
              onClick={() => setUserForm(new User())}
            >
              Clear
            </button>
            <button
              type="submit"
              className={loading ? "blackBtn_" : "blackBtn"}
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
