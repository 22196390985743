import { Component, StrictMode } from "react";
import AboutContainer from "../components/about";
import ScreenHeader, { ScreenFooter } from "../components/foot&head";
import { DragMoreBtn } from "../components/form";
import MediaGallery from "../components/mediaGallery";
import EventsList from "../components/eventsList";
import cs from "../styles/home.module.css";
import NewsList from "../components/newsList";
import { allNews } from "../statics/content";

// const bannerItems = [
//   {
//     title: "UNIVERSAL COUTURE",
//     desc: "Universal Couture is a premier exhibition organizing company based in the vibrant city of Dubai, at the crossroads of innovation and commerce. With an unwavering commitment to excellence and a track record of successful events, we are a trusted partner for businesses seeking to showcase their offerings on a global stage.",
//   },
//   {
//     title: "UC AGENCY",
//     desc: "Unlock Your Full Potential with Universal Couture – We Believe in Your Unique Beauty",
//   },
//   {
//     title: "UC ACADEMY",
//     desc: "Modeling academies offer a range of classes and workshops to teach aspiring models various aspects of the industry, including runway walking, posing, photography, makeup application, skincare, and fashion styling. These classes help models refine their skills and gain confidence.",
//   },
// ];

export default class HomeScreen extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      bannerClicked: false,
      // bannerItem: bannerItems[0],
    };
  }

  componentDidMount(): void {
    // var itemCount = 0;
    // setInterval(() => {
    //   if (itemCount === 2) itemCount = 0;
    //   else itemCount++;
    //   this.setState({ bannerItem: bannerItems[itemCount] });
    // }, 8000);
    setTimeout(() => this.setState({ bannerClicked: true }), 2000);
  }

  _onClickAnyCategory = (path: any) => {
    this.props.setPage(path);
    setTimeout(() => this.setState({ bannerClicked: false }), 1000);
  };

  render() {
    const { bannerClicked }: any = this.state;
    const { setPage, events }: any = this.props;
    return (
      <StrictMode>
        <div className={cs.home}>
          {/* <video className={cs.banner} autoPlay playsInline loop muted>
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/avidhilda-7f3aa.appspot.com/o/Videos%2FPrees%20Confrance.webm?alt=media&token=11a72c92-b51e-450f-be61-2a5c15627e12"
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className={cs.bannerDesc}>
            <div className={cs.titleAnime}>
              <div className={cs.title}>{bannerItem?.title}</div>
              <div className={cs.desc}>{bannerItem?.desc}</div>
            </div>
          </div> */}

          <div className={cs.bannerContentArea}>
            <div className={cs.bannerContentBody}>
              <div className={cs.header}></div>
            </div>
          </div>
          <div className={cs.loaderPage} />
          {/* <DragMoreBtn onClick={() => this.setState({ bannerClicked: true })} /> */}
          <div className={bannerClicked ? cs.page1_ : cs.page1}>
            <div className={cs.secBtnList}>
              <div
                className={cs.secBtn}
                onClick={() => this._onClickAnyCategory("events")}
              >
                <div className={cs.secBtnBody}>
                  <div className={cs.secBtnHeader}>EVENTS</div>
                  <div className={cs.desc}>
                    An Extravaganza of Style! 🌟 Join us for a series of
                    exclusive fashion events that promise to redefine the way
                    you experience glamour and trends.
                  </div>
                </div>
              </div>
              <div
                className={cs.secBtn1}
                onClick={() => {
                  this.props.setPage("agency");
                  setTimeout(
                    () => this.setState({ bannerClicked: false }),
                    1000
                  );
                }}
              >
                <div className={cs.secBtnBody}>
                  <div className={cs.secBtnHeader}>AGENCY </div>
                  <div className={cs.desc}>
                    Unlock Your Full Potential with Universal Couture – We
                    Believe in Your Unique Beauty
                  </div>
                </div>
              </div>
              <div
                className={cs.secBtn2}
                onClick={() => {
                  this.props.setPage("academy");
                  setTimeout(
                    () => this.setState({ bannerClicked: false }),
                    1000
                  );
                }}
              >
                <div className={cs.secBtnBody}>
                  <div className={cs.secBtnHeader}>ACADEMY</div>
                  <div className={cs.desc}>
                    Modeling academies offer a range of classes and workshops to
                    teach aspiring models various aspects of the industry,
                    including runway walking, posing, photography, makeup
                    application, skincare, and fashion styling. These classes
                    help models refine their skills and gain confidence.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {bannerClicked && (
          <StrictMode>
            <AboutContainer />
            <MediaGallery />
            <EventsList events={events} />
            <NewsList events={allNews} />
            <ScreenFooter setPage={setPage} />
            <ScreenHeader setPage={setPage} />
          </StrictMode>
        )}
      </StrictMode>
    );
  }
}
